import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { Layout } from "../components/layout/layout";
import Seo from "../components/seo/seo";
import { SsyLink } from "../components/ssyLink/ssyLink";
import { SsyIntersectionObserver } from "../components/ssyIntersectionObserver/ssyIntersectionObserver";
import { RaisingBox } from "../components/raisingBox/raisingBox";
import style from "../assets/css/app.module.css";

const AppPage = (props) => {
  const intl = useIntl();
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "APP_META_TITLE" })}
        lang={intl.formatMessage({ id: "MAIN_LANGUAGE" })}
        url={props.location.pathname}
        description={intl.formatMessage({ id: "APP_META_DESCRIPTION" })}
      />

      <SsyIntersectionObserver>
      <RaisingBox>
        <section className={style.appPage}>
     
            <h1> {intl.formatMessage({ id: "APP_TITLE" })}</h1>
            <SsyLink
              type="primary"
              internal={false}
              to={`${process.env.GATSBY_IOS_URL}${intl.formatMessage({
                id: "MAIN_LANGUAGE",
              })}`}
              label={intl.formatMessage({ id: "APP_IOS" })}
            />
            <SsyLink
              type="primary"
              internal={false}
              to={`${process.env.GATSBY_ANDROID_URL}`}
              label={intl.formatMessage({ id: "APP_ANDROID" })}
            />
        </section>
        </RaisingBox>
      </SsyIntersectionObserver>
    </Layout>
  );
};

export default AppPage;
